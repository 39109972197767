import { MetaSwiper, getPosition } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

AOS.init({
	startEvent: "load",
	disableMutationObserver: false,
	duration: 600,
	once: true,
});

AOS.refresh(true);

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
	// We execute the same script as before
	vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	document.documentElement.style.setProperty(
		"--header-height",
		`${
			document.getElementsByClassName("header")[0].getBoundingClientRect()
				.height
		}px`
	);
});

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
	return document.documentElement || document.body;
};

if (header && header.classList.contains("fixed")) {
	document.addEventListener("scroll", () => {
		if (scrollContainer().scrollTop > 0) {
			header.classList.add("scrolled");
		} else if (scrollContainer().scrollTop == 0) {
			header.classList.remove("scrolled");
		}
	});
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
	document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
	$(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
	if (isMobileMenuEnable) {
		//close all opened sub menu
		$(".menu-item.dropdown.active .dropdown-menu").slideUp({
			complete: function () {
				$(this).closest(".dropdown").removeClass("active");
			},
		});

		//open current submenu
		$(this).closest(".menu-item.dropdown").toggleClass("active");
		if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
			e.preventDefault();
			$(this).next(".dropdown-menu").slideDown();
		}
	}
});

// toggle menu handler
function menuToggle() {
	$(".menu-toggle").toggleClass("active");
	$(".burger-text").toggleClass("active");
	$(".navbar-nav").toggleClass("active");
	$(".header .logo").toggleClass("active");
	$(".header-close-wrapper").toggleClass("active");
	// LockScroll when burger open and enable when closed and enable scroll on menu
	scrollLock.getScrollState()
		? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
		: scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
	isMobileMenuEnable =
		$(window).outerWidth() <= mobileMenuStartPoint ||
		$(".mobile-header").length;
	if (!isMobileMenuEnable) {
		$(".dropdown-menu").css("display", "");
		$(".header-close-wrapper").removeClass("active");
		$(".menu-item.active").removeClass("active");
		$(".navbar-nav").removeClass("active");
		$(".header .logo").toggleClass("active");
		$(".burger-text").removeClass("active");
		$(".menu-toggle").removeClass("active");
		// LockScroll when burger open and enable when closed
		scrollLock.enablePageScroll();
	}
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".burger-text").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test
function isSafari() {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
if (isSafari()) {
	const mainElement = document.getElementsByClassName("main")[0];
	mainElement.classList.add("is-safari");
}

const choicesDOM = document.querySelector(["[data-choices]"]);
if (choicesDOM) {
	const choicesArr = document.querySelectorAll(["[data-choices]"]);
	for (let i = 0; i < choicesArr.length; i++) {
		new Choices(choicesArr[i], {
			searchEnabled: false,
			itemSelectText: "",
			placeholder: true,
		});
	}
}

function canvasMain() {
	const canvasMain = document.getElementsByClassName("canvas-main-gradient")[0];

	if (canvasMain) {
		const canvasMainArr = document.querySelectorAll(".canvas-main-gradient");
		canvasMainArr.forEach((elem, i) => {
			const parentCard = elem.parentNode.parentNode;
			const granimInstance = new Granim({
				element: elem,
				direction: "diagonal",
				isPausedWhenNotInView: false,
				states: {
					"default-state": {
						gradients: [
							["#f0b254", "#da3a78"],
							["#da3a78", "#f0b254"],
						],
						transitionSpeed: 1500,
						loop: true,
					},
					// "start-state": {
					// 	gradients: [
					// 		["#da3a78", "#f0b254"],
					// 		["#f0b254", "#da3a78"],
					// 	],
					// 	transitionSpeed: 1000,
					// 	loop: false,
					// },
				},
			});
			granimInstance.pause();

			parentCard.addEventListener("mouseenter", () => {
				// granimInstance.changeState("default-state");
				granimInstance.play();
			});

			parentCard.addEventListener("mouseleave", () => {
				// granimInstance.changeState("start-state");
				granimInstance.pause();
			});
		});
	}
}
canvasMain();

/*const background = document.querySelector(".background");

document.addEventListener("mousemove", (event) => {
	const x = event.clientX / window.innerWidth;
	const y = event.clientY / window.innerHeight;
	const gradientAngle = (Math.atan2(y - 0.5, x - 0.5) * 180) / Math.PI + 180;
	const color1 = "#f0b254";
	const color2 = "#da3a78";
	const backgroundStyle = `linear-gradient(${gradientAngle}deg, ${color1}, ${color2})`;

	background.style.background = backgroundStyle;
});*/

const canvasSection = document.getElementsByClassName(
	"canvas-section-gradient"
)[0];

if (canvasSection) {
	const canvasSectionArr = document.querySelectorAll(
		".canvas-section-gradient"
	);
	canvasSectionArr.forEach((elem, i) => {
		const parent = elem.parentNode;
		const granimInstance = new Granim({
			element: elem,
			direction: "diagonal",
			isPausedWhenNotInView: false,
			states: {
				"default-state": {
					gradients: [
						["#F0B254", "#DA3A78"],
						["#DA3A78", "#F0B254"],
					],
					transitionSpeed: 3500,
					loop: true,
				},
			},
		});
		// granimInstance.pause();
		//
		// parent.addEventListener("mouseenter", () => {
		// 	// granimInstance.changeState("default-state");
		// 	granimInstance.play();
		// });
		//
		// parent.addEventListener("mouseleave", () => {
		// 	// granimInstance.changeState("start-state");
		// 	granimInstance.pause();
		// });
	});
}

// Sliders

const areasSlider = document.getElementsByClassName("areas-slider")[0];
if (areasSlider) {
	const areasSlidesArr = areasSlider.querySelectorAll(".swiper-slide");

	if (areasSlidesArr.length >= 3) {
		const areasSwiper = new Swiper(".areas-slider", {
			slidesPerView: 1,
			spaceBetween: 24,
			threshold: 10,
			autoplay: {
				delay: 3500,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			navigation: {
				prevEl: ".areas-slider-buttons.slider-buttons .slider-btn-prev",
				nextEl: ".areas-slider-buttons.slider-buttons .slider-btn-next",
			},
			breakpoints: {
				552: {
					slidesPerView: 2,
					spaceBetween: 16,
				},
				768: {
					slidesPerView: 2,
					spaceBetween: 24,
				},
				1200: {
					slidesPerView: 2,
					spaceBetween: 56,
				},
				1600: {
					slidesPerView: 3,
				}
			},
		});
	}
}

const testimonialsSlider = document.getElementsByClassName(
	"testimonials-slider"
)[0];

if (testimonialsSlider) {
	const testimonialsSwiper = new Swiper(".testimonials-slider", {
		slidesPerView: 1,
		spaceBetween: 20,
		threshold: 10,
		/*effect: "fade",
		fadeEffect: {
			crossFade: true,
		},*/
		effect: "creative",
		creativeEffect: {
			prev: {
				translate: ["-120%", 0, -500],
				opacity: 0,
			},
			next: {
				translate: ["50%", 0, -600],
				opacity: 0,
			},
		},
		pagination: {
			el: ".testimonials-slider__pagination",
			type: "fraction",
			formatFractionCurrent: function (number) {
				return number < 10 ? "0" + number : number;
			},
			formatFractionTotal: function (number) {
				return number < 10 ? "0" + number : number;
			},
		},
		navigation: {
			prevEl: ".testimonials-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".testimonials-slider-buttons.slider-buttons .slider-btn-next",
		},
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
	});
}

const jobsSlider = document.getElementsByClassName("jobs-slider")[0];

if (jobsSlider) {
	const jobsSwiper = new Swiper(".jobs-slider", {
		slidesPerView: 1,
		spaceBetween: 30,
		threshold: 10,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},

		navigation: {
			prevEl: ".jobs-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".jobs-slider-buttons.slider-buttons .slider-btn-next",
		},

		breakpoints: {
			552: {
				slidesPerView: 1.5,
				spaceBetween: 24,
			},
			652: {
				slidesPerView: 2,
				spaceBetween: 24,
			},
			1200: {
				slidesPerView: 2,
				spaceBetween: 30,
			},
		},

		on: {
			init: function () {
				setTimeout(function () {
					const jobsCardTitleArr = Array.from(jobsSlider.querySelectorAll('.job-card__title-content'));
					const heights = jobsCardTitleArr.map(el => el.offsetHeight);
					const maxHeight = Math.max(...heights);
					jobsCardTitleArr.forEach(el => {
						el.style.minHeight = `${maxHeight}px`;
					});
				}, 350)
			},
		}
	});
}

const gallerySlider = document.getElementsByClassName("gallery-slider")[0];

if (gallerySlider) {
	const slidesArr = gallerySlider.querySelectorAll(".swiper-slide");
	let loop = false;
	let loopedSlides = false;

	if (slidesArr.length >= 4) {
		loop = true;
		loopedSlides = slidesArr.length;
	}

	const gallerySwiper = new Swiper(".gallery-slider", {
		slidesPerView: 1,
		loop: loop,
		loopedSlides: loopedSlides,
		spaceBetween: 16,
		threshold: 10,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		grabCursor: true,
		breakpoints: {
			652: {
				slidesPerView: 2,
				spaceBetween: 16,
			},
		},
	});
}

const testimonialsOffersSlider = document.getElementsByClassName(
	"testimonials-offers-slider"
)[0];

if (testimonialsOffersSlider) {
	const testimonialsOffersSwiper = new Swiper(".testimonials-offers-slider", {
		slidesPerView: 2,
		spaceBetween: 16,
		threshold: 10,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		navigation: {
			prevEl: ".testimonials-offers-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".testimonials-offers-slider-buttons.slider-buttons .slider-btn-next",
		},
		grabCursor: true,
		breakpoints: {
			421: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			552: {
				slidesPerView: 4,
				spaceBetween: 16,
			},
			652: {
				slidesPerView: 5,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 6,
				spaceBetween: 16,
			},
		},
	});
}

const offersSlider = document.getElementsByClassName("offers-slider")[0];
if (offersSlider) {
	let offersSwiper;
	function initializeSwiper() {
		offersSwiper = new Swiper(".offers-slider", {
			slidesPerView: 1,
			spaceBetween: 16,
			grid: {
				rows: 2,
			},
			threshold: 10,
			/*loop: true,
            loopedSlides: 10,*/
			autoplay: {
				delay: 3500,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			navigation: {
				prevEl: ".offers-slider-buttons.slider-buttons .slider-btn-prev",
				nextEl: ".offers-slider-buttons.slider-buttons .slider-btn-next",
			},
			breakpoints: {
				452: {
					slidesPerView: 2,
					spaceBetween: 16,
					grid: {
						rows: 2,
					},
				},
			},
		});
	}

	function destroySwiper() {
		if (offersSwiper) {
			offersSwiper.destroy();
			offersSwiper = undefined;
		}
	}

	function handleWindowResize() {
		const slidesArr = offersSlider.querySelectorAll(".swiper-slide");
		const sliderWrapper = offersSlider.querySelector(".swiper-wrapper");
		if (window.innerWidth <= 652) {
			if (!offersSwiper) {
				initializeSwiper();
			}
			let maxHeight = 0;

			for (let i = 0; i < slidesArr.length; i++) {
				const slide = slidesArr[i];
				const slideHeight = slide.offsetHeight;

				if (slideHeight > maxHeight) {
					maxHeight = slideHeight;
				}

				if (i % 2 !== 0) {
					slide.classList.add("not-border-top-mob");
				}
			}

			for (let i = 0; i < slidesArr.length; i++) {
				const slide = slidesArr[i];
				slide.style.minHeight = `${maxHeight}px`;
				sliderWrapper.style.maxHeight = `${maxHeight * 2.2}px`;
			}
		} else {
			for (let i = 0; i < slidesArr.length; i++) {
				const slide = slidesArr[i];
				slide.style.minHeight = `none`;
			}
			destroySwiper();
		}
	}

	handleWindowResize();
	window.addEventListener("resize", handleWindowResize);
}

if (document.getElementsByClassName("offers-slider__item")[0]) {
	const parentsArr = document.querySelectorAll(".offers-slider__item");
	parentsArr.forEach((parent, i) => {
		if (i >= 4 && window.innerWidth >= 902) {
			parent.classList.add("not-border-top");
		} else if (i >= 3 && window.innerWidth <= 901) {
			parent.classList.add("not-border-top");
		}
	});

	const childArr = document.querySelectorAll(".offers-slider__line");
	childArr.forEach((item, i) => {
		if (i >= 9) {
			item.style.transitionDelay = `1.0${i + 1}s`;
		} else if (i >= 19) {
			item.style.transitionDelay = `1.1${i + 1}s`;
		} else {
			item.style.transitionDelay = `0.${i + 1}s`;
		}
	});
}

if (document.getElementsByClassName("testimonials-offers__item")[0]) {
	const childArr = document.querySelectorAll(".testimonials-offers__line");
	childArr.forEach((item, i) => {
		if (i >= 9) {
			item.style.transitionDelay = `1.0${i + 1}s`;
		} else if (i >= 19) {
			item.style.transitionDelay = `1.1${i + 1}s`;
		} else {
			item.style.transitionDelay = `0.${i + 1}s`;
		}
	});
}

const statsSlider = document.getElementsByClassName("stats-slider")[0];
if (statsSlider) {
	const statsSlidesArr = statsSlider.querySelectorAll(".swiper-slide");

	if (statsSlidesArr.length >= 4) {
		const statsSwiper = new Swiper(".stats-slider", {
			slidesPerView: 1,
			spaceBetween: 16,
			threshold: 10,
			autoplay: {
				delay: 3500,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},

			navigation: {
				prevEl: ".stats-slider-buttons.slider-buttons .slider-btn-prev",
				nextEl: ".stats-slider-buttons.slider-buttons .slider-btn-next",
			},

			breakpoints: {
				552: {
					slidesPerView: 2,
				},
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
				},
			},
		});
	}
}

const teamSlider = document.getElementsByClassName("team-slider")[0];
if (teamSlider) {
	const teamSlidesArr = teamSlider.querySelectorAll(".swiper-slide");

	if (teamSlidesArr.length >= 3) {
		const teamSwiper = new Swiper(".team-slider", {
			slidesPerView: 1,
			spaceBetween: 16,
			threshold: 10,
			autoplay: {
				delay: 3500,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},

			navigation: {
				prevEl: ".team-slider-buttons.slider-buttons .slider-btn-prev",
				nextEl: ".team-slider-buttons.slider-buttons .slider-btn-next",
			},

			breakpoints: {
				552: {
					slidesPerView: 2,
				},
				902: {
					slidesPerView: 3,
				},
			},
		});
	}
}

const areasTextSlider = document.getElementsByClassName("areas-text-slider")[0];

if (areasTextSlider) {
	const areasToggleCardArr = document.querySelectorAll(
		".areas-card.areas-page-type"
	);
	const areasToggleBtnArr = document.querySelectorAll(
		".areas-card.areas-page-type .btn"
	);
	const areasTextSwiper = new Swiper(".areas-text-slider", {
		slidesPerView: 1,
		spaceBetween: 30,
		allowTouchMove: false,
		effect: "creative",
		creativeEffect: {
			prev: {
				translate: ["-120%", 0, -500],
				opacity: 0,
			},
			next: {
				translate: ["120%", 0, -500],
				opacity: 0,
			},
		},
	});
	areasToggleBtnArr.forEach((btn, i) => {
		btn.addEventListener("click", () => {
			for (let j = 0; j < areasToggleCardArr.length; j++) {
				if (areasToggleCardArr[j].classList.contains("active")) {
					areasToggleCardArr[j].classList.remove("active");
				}
			}
			areasToggleCardArr[i].classList.add("active");
			areasTextSwiper.slideTo(i);
		});
	});
}

const contactSlider = document.getElementsByClassName("contact-slider")[0];
if (contactSlider) {
	const teamSlidesArr = contactSlider.querySelectorAll(".swiper-slide");
	const contactSwiper = new Swiper(".contact-slider", {
		slidesPerView: 1,
		spaceBetween: 16,
		threshold: 10,
		rewind: true,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},

		breakpoints: {
			1025: {
				autoplay: false,
			},
		},

		navigation: {
			prevEl: ".contact-slider-buttons.slider-buttons .slider-btn-prev",
			nextEl: ".contact-slider-buttons.slider-buttons .slider-btn-next",
		},

		effect: "creative",
		creativeEffect: {
			limitProgress: Infinity,

			prev: {
				translate: [0, 0, -308],
				opacity: 0,
			},

			next: {
				translate: ["calc(100% + 16px)", 0, 0],
				opacity: 1,
			},
		},
	});

	function disableSwiper() {
		if (window.innerWidth >= 852 && teamSlidesArr.length <= 3) {
			contactSwiper.disable();
		}

		if (window.innerWidth < 851) {
			contactSwiper.enable();
		}
	}

	disableSwiper();

	window.onresize = () => disableSwiper();
}

const article = document.querySelector(".content-element.article-type");
if (article) {
	const headingArr = article.querySelectorAll("h3");
	for (let i = 0; i < headingArr.length; i++) {
		headingArr[i].classList.add("scroll-target");
		headingArr[i].classList.add("scroll-decor");
	}
}

const scrollEvents = () => {
	const trueScrollTarget = document.getElementsByClassName("scroll-target")[0];

	if (trueScrollTarget) {
		const scrollTarget = document.querySelectorAll(".scroll-target");
		let threshold = 0.5;
		let decorsArr = [];
		let typedArr = [];
		let typedStringArr = [];

		if (document.getElementsByClassName("scroll-decor")[0]) {
			decorsArr = document.querySelectorAll(".scroll-decor");
		}

		if (document.getElementsByClassName("scroll-typed")[0]) {
			typedArr = document.querySelectorAll(".scroll-typed");
		}

		if (document.getElementsByClassName("scroll-typed")[0]) {
			typedStringArr = document.querySelectorAll(".typed-string");
		}

		if ("IntersectionObserver" in window) {
			console.log("IntersectionObserver is supported");
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry, i) => {
						if (entry.isIntersecting) {
							if (decorsArr.length !== 0) {
								decorsArr.forEach((elem, i) => {
									if (entry.target === elem) {
										elem.classList.add("animation");
									}
								});
							}

							if (typedArr.length !== 0) {
								typedArr.forEach((elem, i) => {
									if (entry.target === elem) {
										elem.classList.add("animation");
										if (!elem.classList.contains("animation-yes")) {
											elem.classList.add("animation-yes");
											let typed = new Typed(elem, {
												stringsElement: typedStringArr[i],
												typeSpeed: 50,
												startDelay: 0,
												backSpeed: 50,
												loop: false,
												onComplete: (self) => {
													if (
														elem.parentNode.querySelector(
															".scroll-typed-line-decor"
														)
													) {
														elem.parentNode
															.querySelector(".scroll-typed-line-decor")
															.classList.add("animation");
													}
												},
											});
										}
									}
								});
							}
						}
					});
				},
				{
					threshold: 0,
					rootMargin: "0% 0% -5% 0%",
				}
			);

			scrollTarget.forEach((target, index) => {
				observer.observe(target);
			});
		} else {
			console.log("IntersectionObserver doesn't supported!");
		}
	}
};

scrollEvents();

/**
 * @function for floating sidebar
 * */
const contentSticky = document.getElementById("content-sticky");
const rightSidebar = document.getElementById("sidebar");
if (rightSidebar && window.innerWidth >= 902) {
	const sidebarBuffer = rightSidebar.querySelector(".sidebar__buffer");
	const sidebarInner = rightSidebar.querySelector(".sidebar__inner");
	// const headerHeight = +getRootStyle("--header-height").replace("px", "");
	const headerHeight = 0;

	// gap on top and bottom of sticky sidebar
	const gap = 80;

	let lastScrollTop = 0;

	// if flag true === scrolling down else scrolling up
	let flag = null;

	// if flagHeight true === sidebar is higher than viewport height
	let flagHeight =
		document.querySelector(".sidebar__inner").getBoundingClientRect().height >
		window.innerHeight - headerHeight;

	function calcHeightOfSidebar(flag) {
		const contentStickyTop = getPosition(contentSticky).y;
		const sidebarInnerTop = getPosition(sidebarInner).y;
		sidebarBuffer.style.height = `${sidebarInnerTop - contentStickyTop}px`;

		if (flag) {
			// scroll up
			sidebarInner.style.top = "";
			sidebarInner.style.bottom = `${
				-headerHeight -
				gap +
				window.innerHeight -
				sidebarInner.getBoundingClientRect().height
			}px`;
		} else {
			// scroll down
			sidebarInner.style.bottom = "";
			sidebarInner.style.top = `${
				Math.min(
					window.innerHeight - sidebarInner.getBoundingClientRect().height,
					headerHeight
				) - gap
			}px`;
		}
		flagHeight =
			sidebarInner.getBoundingClientRect().height >
			window.innerHeight - headerHeight;
	}

	function resetSticky() {
		sidebarInner.style.bottom = "";
		sidebarInner.style.top = `${headerHeight + gap}px`;
		sidebarBuffer.style.height = "0";
	}

	if (!flagHeight) {
		resetSticky();
	} else {
		calcHeightOfSidebar(false);
	}

	window.addEventListener("scroll", function () {
		let st = window.pageYOffset || document.documentElement.scrollTop;

		if (!flagHeight) {
			resetSticky();
		}

		if (st > lastScrollTop && !flag) {
			// scroll down
			calcHeightOfSidebar(flag);
			flag = true;
		} else if (st < lastScrollTop && flag) {
			// scroll up
			calcHeightOfSidebar(flag);
			flag = false;
		}
		lastScrollTop = st <= 0 ? 0 : st;
	});

	window.addEventListener("resize", function (e) {
		if (!flagHeight) {
			resetSticky();
		}

		calcHeightOfSidebar(true);
		calcHeightOfSidebar(false);
	});
}

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);

var scroll = new SmoothScroll('a[href*="#"]');

// if (document.getElementsByClassName("lottie-animation")[0]) {
// 	const lottieAnimationArr = document.querySelectorAll(".lottie-animation");
//
// 	const options = {
// 		root: null,
// 		threshold: 0,
// 		rootMargin: "0% 0% -2% 0%",
// 	};
//
// 	const intersectionCallback = (entries, observer) => {
// 		entries.forEach((entry) => {
// 			if (entry.isIntersecting) {
// 				if (!entry.target.classList.contains("animation-yes")) {
// 					let animation = lottie.loadAnimation({
// 						container: entry.target,
// 						renderer: "svg",
// 						loop: true,
// 						autoplay: true,
// 						path: `./lottie/lines_white_json.json`,
// 					});
// 					animation.setSpeed(1.1);
// 					entry.target.classList.add("animation-yes");
// 				} else {
// 					entry.target.getAnimations().forEach((anim) => anim.play());
// 				}
//
// 				/*observer.unobserve(entry.target);*/
// 			} else {
// 				entry.target.getAnimations().forEach((anim) => anim.pause());
// 			}
// 		});
// 	};
//
// 	const intersectionLottieObserve = new IntersectionObserver(
// 		intersectionCallback,
// 		options
// 	);
//
// 	lottieAnimationArr.forEach((element) => {
// 		intersectionLottieObserve.observe(element);
// 	});
// }
//
// if (document.getElementsByClassName("lottie-testimonial-animation")[0]) {
// 	const lottieTestimonialsAnimationArr = document.querySelectorAll(
// 		".lottie-testimonial-animation"
// 	);
//
// 	const options = {
// 		root: null,
// 		threshold: 0,
// 		rootMargin: "0% 0% -15% 0%",
// 	};
//
// 	const intersectionCallback = (entries, observer) => {
// 		entries.forEach((entry) => {
// 			if (entry.isIntersecting) {
// 				let animation = lottie.loadAnimation({
// 					container: entry.target,
// 					renderer: "svg",
// 					loop: false,
// 					autoplay: false,
// 					path: `./lottie/testimonials-section.json`,
// 				});
// 				entry.target.parentNode.classList.add("active");
// 				animation.setSpeed(1.1);
// 				animation.play();
// 				observer.unobserve(entry.target);
// 			}
// 		});
// 	};
//
// 	const intersectionTestimonialsObserve = new IntersectionObserver(
// 		intersectionCallback,
// 		options
// 	);
//
// 	lottieTestimonialsAnimationArr.forEach((element) => {
// 		intersectionTestimonialsObserve.observe(element);
// 	});
// }

// function parallax(e) {
// 	const elements = document.querySelectorAll(".decor-parallax");
//
// 	elements.forEach((elem) => {
// 		const speed = elem.getAttribute("data-speed");
// 		const rect = elem.getBoundingClientRect();
//
// 		if (rect.top < window.innerHeight && rect.bottom > 0) {
// 			const x = (window.innerWidth - e.pageX * speed) / 400;
// 			const y = (window.innerHeight - e.pageY * speed) / 400;
//
// 			elem.style.transform = `translateX(${x}px) translateY(${y}px)`;
// 		}
// 	});
// }
//
// if (
// 	window.innerWidth >= 1025 &&
// 	document.getElementsByClassName("decor-parallax")[0]
// ) {
// 	document.addEventListener("mousemove", parallax);
// }
/*if(document.getElementById('pagination')) {
	const pagination = document.getElementById('pagination');
	pagination.addEventListener('click', () => {
		updateAnimations();
	})
}*/

function jobsSearchHeightTitles() {
	const jobsList = document.getElementsByClassName('result-jobs-section__list')[0];
	if(jobsList && window.innerWidth >= 721) {
		const jobsCardArr = Array.from(jobsList.querySelectorAll('.job-card'));
		const result = jobsCardArr.reduce((acc, curr, index) => {
			if (index % 2 === 0) {
				acc.push(jobsCardArr.slice(index, index + 2));
			}
			return acc;
		}, []);

		result.forEach(subArray => {
			const [firstCard, secondCard] = subArray;
			const maxHeight = Math.max(firstCard.querySelector('.job-card__title-content').offsetHeight, secondCard.querySelector('.job-card__title-content').offsetHeight);
			console.log(maxHeight);
			if (firstCard.querySelector('.job-card__title-content').offsetHeight < maxHeight) {
				firstCard.querySelector('.job-card__title-content').style.minHeight = `${maxHeight}px`;
			} else if (secondCard.querySelector('.job-card__title-content').offsetHeight < maxHeight) {
				secondCard.querySelector('.job-card__title-content').style.minHeight = `${maxHeight}px`;
			}
		});
	}
}
jobsSearchHeightTitles();

const tiltElements = document.querySelectorAll('[data-tilt]');

if(tiltElements[0]) {
	if (window.innerWidth <= 1024) {
		tiltElements.forEach((tiltElem, i) => {
			tiltElem.vanillaTilt.destroy();
		});
	}
}



window.updateAnimations = function updateAnimations() {
	scrollEvents();
	canvasMain();
	jobsSearchHeightTitles();

	if(document.querySelectorAll('[data-tilt]') && window.innerWidth > 1024) {
		const tiltElements = document.querySelectorAll('[data-tilt]');
		tiltElements.forEach((tiltElem, i) => {
			VanillaTilt.init(tiltElem, {
				speed: 500,
				max: 3
			});
		})
	}
}

